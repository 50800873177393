import { menu } from "./menu";
//自定义方法
export class MethodsUtil {
    //根据路由获取index和child
    static getRouteIndexAndChild(route) {
        let index, child;
        menu.map((sub, i) => {
            if (route.name == "" && sub.src == route.path) {
                index = i;
                child = -1;
            } else {
                if (sub.children.length) {
                    let j = sub.children.findIndex(
                        (item) => item.name == route.name
                    );
                    if (j >= 0) {
                        index = i;
                        child = j;
                    }
                }
            }
        });
        let name = route.name;
        let nameChild0 = [
            "device-detail",
            "device-notice",
            "project-detail",
            "news-detail1",
        ];
        if (nameChild0.includes(name)) {
            child = 0;
        }
        let nameChild1 = [
            "competition-detail",
            "eTalk",
            "eTalk-detail",
            "news-detail",
            "e-club-detail",
            "eTalk-order",
        ];
        if (nameChild1.includes(name)) {
            child = 1;
        }
        if (name == "courses-detail") {
            child = 2;
        }
        let namechild3 = [
            "guidence-detail",
            "competition-activities",
            "competition-activities-detail",
        ];
        if (namechild3.includes(name)) {
            child = 3;
        }
        let namechild4 = ["science-detail", "project-index", "science"];
        if (namechild4.includes(name)) {
            child = 4;
        }
        return {
            index,
            child,
        };
    }
}
