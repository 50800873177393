<template>
    <div
        class="container"
        :class="{
            'gov-gb':
                $route.name == 'govnews' || $route.name == 'govnews-detail1',
        }"
    >
        <div
            class="gov-news-bg"
            v-if="$route.name == 'govnews' || $route.name == 'govnews-detail1'"
        ></div>
        <div v-else class="border-container"></div>
        <div class="link-container" v-if="menu.length">
            <Breadcrumb separator=">" style="color: #fff">
                <BreadcrumbItem to="/">
                    <span
                        v-if="
                            $route.name == 'govnews' ||
                            $route.name == 'govnews-detail1'
                        "
                        class="link gov-link"
                        ><span class="logo-icon"></span
                        >{{ "首页" | language }}</span
                    >
                    <span v-else class="link">{{ "首页" | language }}</span>
                </BreadcrumbItem>
                <BreadcrumbItem
                    :to="menu[index].src"
                    class="link"
                    v-if="child != -1 && $route.name !== 'introduction'"
                    ><span class="link">{{
                        menu[index].label | language
                    }}</span></BreadcrumbItem
                >
                <BreadcrumbItem class="link last">{{
                    getText | language
                }}</BreadcrumbItem>
            </Breadcrumb>
        </div>
    </div>
</template>

<script>
import { menu } from "../../assets/util/menu";
import { languageTranslation } from "@/assets/util/language";
export default {
    props: {
        index: {
            default: 0,
        },
        child: {
            default: 0,
        },
    },
    data() {
        return {
            menu: [],
        };
    },
    mounted() {
        this.menu = menu;
    },
    computed: {
        getText() {
            console.log("thomas--->>>", this.menu, this.index, this.child);
            if (this.child == -1) {
                return this.menu[this.index].label;
            }
            let text = this.menu[this.index].children[this.child].label;
            let result = text;
            if (this.$route.name.indexOf("-detail") >= 0) {
                result = `${text}详情`;
            }
            return result;
        },
    },
    methods: {
        // getText() {
        //   let text = this.menu[this.index].children[this.child].label;
        //   if (this.$route.name.indexOf("-detail") >= 0) {
        //     return `${text}详情`;
        //   } else {
        //     return text;
        //   }
        // }
    },
};
</script>
<style scoped>
.ivu-breadcrumb-item-separator {
    color: #2c8dfc !important;
}
</style>
<style lang="less" scoped>
.container {
    min-width: 1200px;
    position: relative;
    height: 100px;
    overflow: hidden;
    .border-container {
        // width: 0;
        // height: 0;
        border-color: transparent #00305b;
        border-width: 0 0 100px 200vw;
        border-style: solid;
    }
    .gov-news-bg {
        border-color: transparent rgba(178,20,26,0.8);
        border-width: 0 0 100px 200vw;
        border-style: solid;
        border-bottom-image: url("../../assets/img/red1px.png");
    }

    .gov-link {
        position: relative;
    }
    .logo-icon {
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: middle;
        margin-left: 8px;
        margin-right: 8px;
        background: url("../../assets/img/logo.png") 1px -3px no-repeat;
        background-size: cover;
    }

    .link-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1200px;
        height: 22px;
        // background-color: red;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .link {
            color: #2c8dfc;
        }
        .last {
            color: #fff;
        }
    }
    &.gov-gb {
        .link {
            color: #000;
        }
        .link.last {
            color: #fff;
        }
    }
}
@media screen and (max-width: 960px) {
    .container {
        width: 100%;
        min-width: 100%;
        .link-container {
            width: 100%;
        }
    }
}
</style>
