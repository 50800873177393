<template>
    <div class="activies">
        <index-text-header></index-text-header>
        <div class="base-container" v-if="$route.path !== '/base'">
            <!-- <v-breadcrumb :index="index" :child="child"></v-breadcrumb> -->
            <div class="body">
                <div class="left">
                    <!-- <left-menu :index="index" :child="child"></left-menu> -->
                </div>
                <div class="list_container">
                    <div class="list" v-if="list">
                        <div class="title">
                            {{ "教学发展与学生创新中心简介" | language }}
                        </div>
                        <div class="en_title">INTRODUCTION</div>
                        <div class="content intro" v-html="list.content"></div>
                    </div>
                    <div class="no-date" :class="{ 'show-no-data': isEmpty }" v-else>
                        {{ "没有符合筛选条件的数据" | language }}~
                    </div>
                </div>
                <index-footer></index-footer>
            </div>
        </div>
    </div>
</template>

<script>
import IndexTextHeader from "../../compontents/index/IndexTestHeader";
import IndexFooter from "../../compontents/index/IndexFooter";
import VBreadcrumb from "../../compontents/base/VBreadcrumb.vue";
import { MethodsUtil } from "../../assets/util/method.js";

export default {
    components: {
        IndexTextHeader,
        IndexFooter,
        VBreadcrumb
    },
    data() {
        return {
            list: [],
            keyword: "",
            pageSize: 10,
            pageNum: 0,
            total: 0,
            index: "",
            child: "",
            isEmpty:false
        };
    },
    mounted() {
        this.isEmpty = false;
        this.fetchData();
        // let { index, child } = MethodsUtil.getRouteIndexAndChild(this.$route);
        this.index = 1;
        this.child = 0;
    },
    methods: {
        async fetchData() {
            let res = await this.$http.get(`/home/center/detail`);
            this.list = res;
            if(!res || !res.content){
                this.isEmpty = true;
            }
        }
    },
    watch: {
        $route(val) {
            let { index, child } = MethodsUtil.getRouteIndexAndChild(val);
            this.index = index;
            this.child = child;
        }
    }
};
</script>

<style lang="less" scoped>
.activies {
    .search-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        .search-title {
            margin-bottom: 10px;
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            text-align: left;
        }
    }
    .line {
        height: 1px;
        background: rgba(0, 0, 0, 0.25);
        margin-bottom: 40px;
    }
    .list_container {
        display: flex;
        min-height: 1000px;
        width: 1200px;
        margin: 0 auto;
        .list {
            margin-top: 40px;
            padding-bottom: 40px;
            width: 100%;
            .title {
                opacity: 0.9;
                font-size: 30px;
                color: #000;
                letter-spacing: 0;
                line-height: 32px;
            }
            .en_title {
                margin-bottom: 15px;
                opacity: 0.6;
                font-size: 18px;
                color: #000;
                letter-spacing: 0;
                line-height: 29px;
            }
            .content {
                text-align: left;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                line-height: 22px;
                img {
                    max-width: 100%;
                }
            }
        }
        .no-date {
            width: 200px;
            height: 100px;
            line-height: 100px;
            margin: 60px auto;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            visibility:hidden;
            opacity:0;
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .activies {
        .list_container {
            min-width: 100%;
            padding: 0 8vw;
            width: 100%;
            .list {
                .title {
                    font-size: 22px;
                }
                .en_title {
                    font-size: 16px;
                }
                .content {
                    line-height: 1.5;
                }
            }
        }
    }
}
</style>
<style>
.content img {
    max-width: 100%;
    object-fit: cover;
}
</style>
<style>
.show-no-data {
    visibility: visible !important;
    opacity: 1 !important;
}
</style>